import React from "react";
import {
  Headings,
  PrimaryButton,
  ModalOverlay,
} from "@sentaraui/optimahealth_web";
import { t } from "i18next";

export const ModalOverlayPopUp = ({
  title,
  content,
  open,
  callBack,
}: {
  title?: string;
  content?: string;
  open: boolean;
  callBack: (v: boolean, type: string) => void;
}) => {
  return (
    <ModalOverlay
      onClose={() => callBack(false, "close")}
      isOpen={open}
      overlayInfo="Modal information"
      className="modal-w-medium"
    >
      <Headings
        className="font-twenty fw-500 mb-2 me-4"
        level={3}
        text={title}
        variant={"medium"}
      />
      <p className="mb-4" data-testid="sessionModalDescription">
        {content}
      </p>
      <div className="text-center">
        <PrimaryButton
          data-testid={"modalOkButton"}
          className={"primary_btn btn_block_mob"}
          type="submit"
          onClick={() => callBack(false, "ok")}
        >
          {t("session.label.buttonOk")}
        </PrimaryButton>
      </div>
    </ModalOverlay>
  );
};
