import { DEFAULT_THEME } from "@sentaraui/optimahealth_web";
import {
  FooterNavigation,
  ProfileMenuLinks,
  TopNavLinks,
  avMedBaseUrl,
  memberBaseUrl,
  memberBrokerContactUs,
} from "./Navigation";
import { FooterNavType, LinkListType } from "utils/model/CommonTypes";
import { DEVICE_MANAGEMENT_IMAGE, LINK } from "./Constants";
import favicon from "../../assets/images/favicon.ico";
import faviconAvMed from "../../assets/images/faviconAvMed.ico";

export type BrandType = {
  brandName: string;
  hostNames?: Array<string>;
  footerNavigation: { [x: string]: FooterNavType };
  topNavLinks: { [x: string]: LinkListType };
  profileMenuLinks: { [x: string]: any };
  memberBrokerContactUs: string;
  homeLink: string;
  link: { [x: string]: any };
  oidcConfig: { [x: string]: any };
  image: { [x: string]: any };
};

export type BrandsType = {
  [x: string]: BrandType;
};

export const BRAND_CONFIG: BrandsType = {
  AVMED: {
    brandName: "AVMED",
    hostNames: [
      "brokerdev2.sentarahealthplans.com",
      "brokerqa2.sentarahealthplans.com",
      "brokerreg2.sentarahealthplans.com",
      "uat.broker.avmed.com",
      "broker.avmed.com",
      "dev.broker.avmed.com",
      "qa.broker.avmed.com",
      "reg.broker.avmed.com",
      "mango-bay-055a39c0f.4.azurestaticapps.net",
      "black-sea-0b993090f.4.azurestaticapps.net"
    ],
    footerNavigation: {
      ...FooterNavigation,
      ...{
        footerTermsOfUse: {
          ...FooterNavigation.footerTermsOfUse,
          url: avMedBaseUrl + "/terms-of-use/",
        },
        footerPrivacy: {
          ...FooterNavigation.footerPrivacy,
          url: avMedBaseUrl + "/your-privacy",
        },
        footerLangAssist: {
          ...FooterNavigation.footerLangAssist,
          url: avMedBaseUrl + "/accessibility-statement",
        },
        footerNoticeOfNonDiscrimination: {
          ...FooterNavigation.footerNoticeOfNonDiscrimination,
          url: avMedBaseUrl + "/notice-of-nondiscrimination",
        },
      },
    },
    profileMenuLinks: {
      ...ProfileMenuLinks,
      ...{
        navLinkProfile: {
          ...ProfileMenuLinks.navLinkProfile,
          to: `${avMedBaseUrl}/web/business/agents/`,
        },
      },
    },
    topNavLinks: {
      ...TopNavLinks,
      navTopBrokerHome: {
        ...TopNavLinks.navTopBrokerHome,
        to: `${avMedBaseUrl}/web/business/agents/`,
      },
    },
    memberBrokerContactUs: `${avMedBaseUrl}/contact-us `,
    homeLink: `${avMedBaseUrl}/web/business/agents/`,
    link: {
      privacyPolicy: LINK.PRIVACY_POLICY_AVMED,
      homePageLink: LINK.AGENTHOME_LINK,
      sorc: "mailto:AvMedIFPMAgentSupport@avmed.org",
      sorc1: "mailto:smallgroupsupport@avmed.org",
      dashboardLearnMoreLink: LINK.LEARN_MORE_LINK_DASHBOARD_BRAND,
      supportTileLink: LINK.CONTACT_US_LINK_BRAND,
    },
    oidcConfig: {
      client_id: process.env.REACT_APP_AVMED_BROKER_OIDC_CLIENT_ID,
    },
    image: {
      DuoSentPushImg: DEVICE_MANAGEMENT_IMAGE.image49,
      DuoPushAppleImg: DEVICE_MANAGEMENT_IMAGE.image45,
      DuoPushAndroidImg: DEVICE_MANAGEMENT_IMAGE.image46,
      DuoUserExpAppleImg: DEVICE_MANAGEMENT_IMAGE.image47,
      DuoUserExpAndroidImg: DEVICE_MANAGEMENT_IMAGE.image48,
      DuoStartSetupImg: DEVICE_MANAGEMENT_IMAGE.image50,
      faviconImage: faviconAvMed,
    },
  },
  DEFAULT: {
    brandName: "DEFAULT",
    footerNavigation: FooterNavigation,
    topNavLinks: TopNavLinks,
    profileMenuLinks: ProfileMenuLinks,
    memberBrokerContactUs: memberBrokerContactUs,
    homeLink: `${memberBaseUrl}/brokers/`,
    link: {
      privacyPolicy: LINK.PRIVACY_POLICY,
      homePageLink: LINK.BROKERHOME_LINK,
      sorc: "mailto:webmaster@sentara.com",
      dashboardLearnMoreLink: LINK.LEARN_MORE_LINK_DASHBOARD,
      supportTileLink: LINK.CONTACT_US_LINK,
    },
    oidcConfig: {
      client_id: process.env.REACT_APP_SENTARA_BROKER_OIDC_CLIENT_ID,
    },
    image: {
      DuoSentPushImg: DEVICE_MANAGEMENT_IMAGE.image2,
      DuoPushAppleImg: DEVICE_MANAGEMENT_IMAGE.image3,
      DuoPushAndroidImg: DEVICE_MANAGEMENT_IMAGE.image4,
      DuoUserExpAppleImg: DEVICE_MANAGEMENT_IMAGE.image5,
      DuoUserExpAndroidImg: DEVICE_MANAGEMENT_IMAGE.image6,
      DuoStartSetupImg: DEVICE_MANAGEMENT_IMAGE.image13,
      faviconImage: favicon,
    },
  },
};

export type Theme = typeof DEFAULT_THEME;
