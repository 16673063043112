
import { useTranslation } from "react-i18next";
import { COMMISSION_POLICY_MEDICARE_PLANS } from "utils/constants/Constants";
import { Table } from "@sentaraui/optimahealth_web";

export default function CommissionPolicyMedicarePlanTable() {
  const { t } = useTranslation();
  const header = [
    { text: COMMISSION_POLICY_MEDICARE_PLANS.empty },
    { text: t("commissionPolicy.medicarePlansTableHeaderYear1"), colspan: 2 },
    { text: t("commissionPolicy.medicarePlansTableHeaderYear1"), colspan: 2 },
    { text: t("commissionPolicy.medicarePlansTableHeaderYear2Pluse"), colspan: 2 },
  ]

  const getComissionPolicyData = () => {
    let singleRowData = []
    let tableRowData = []

    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.empty })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.initialEnrollment, colspan: 2 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.renewal, colspan: 2 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.renewal, colspan: 2 })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.empty })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.plan })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.currency })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.plan })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.currency })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.plan })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.currency })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.broker, rowspan: 2, tableStyle: { verticalAlign: 'middle' } })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan1 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan1Price })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan1 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan3Price })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan1 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan3Price })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan2 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan0Price })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan2 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan3Price })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan2 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan3Price })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.empty })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.incentiveProgram, colspan: 2 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.currency })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.empty })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.empty })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.empty })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.SHPHRA })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.SHPHRADSNPIncentive, colspan: 2 })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.brokerPlan2Price })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.empty })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.empty })
    singleRowData.push({ text: COMMISSION_POLICY_MEDICARE_PLANS.empty })
    tableRowData.push(singleRowData)

    return tableRowData
  }

  return (
    <div className="row justify-content-center mb-3">
      <div className="col-lg-10 col-md-9 col-sm-12 col-12">
        <div className="text-center mb-3">
          <strong> {t("commissionPolicy.medicarePlansTableHeader")}</strong>
        </div>
        <Table
          data-testid="tblSmallGroupAndMidMarket"
          header={header}
          rows={getComissionPolicyData()} />
      </div>
    </div>
  );
}
