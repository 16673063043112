import { t } from "i18next";
import { Headings } from "@sentaraui/optimahealth_web";
import { toPascalCase } from "utils/helpers/TextFormaters";
import { ProfileFirstLetter } from "organisms/profile";
import { MenuAccordion } from "./";
import { Brands, useAuth, useBranding } from "providers";
import { SERVICE_NUMBER } from "utils/constants/Constants";
import { useEffect, useState } from "react";

/**
 * Sides menu
 * @returns
 */
export const SideMenu = () => {
  const { user } = useAuth();
  const { brandConfig } = useBranding();
  const [lobs, setLobs] = useState("");

  const getUserLobs = () => {
    if (user?.lobs) {
      const types = user?.lobs.map((lob) => lob.name);

      let sentaraMedicare = 0;
      let sentaraGroup = 0;
      let sentaraIndividual = 0;
      let avmedMedicare = 0;
      let avmedIndividual = 0;
      let avmedGroup = 0;
      let avMedMewa = 0;

      user.lobs?.forEach((lob) => {
        if (lob.id === 2) {
          sentaraMedicare++;
          avmedMedicare++;
        }
        if (lob.id === 1) {
          sentaraGroup++;
          avmedGroup++;
        }
        if (lob.id === 3) {
          avmedIndividual++;
          sentaraIndividual++;
        }
        if (lob.id === 4) {
          avMedMewa++;
        }
      });

      let combinationUser = false;
      let medicareUser = false;
      let commericalUserGroup = false;
      let commericalUserIndividual = false;
      let commericalUser = false;

      if (brandConfig.brandName === Brands.DEFAULT) {
        combinationUser =
          sentaraMedicare > 0 && (sentaraGroup > 0 || sentaraIndividual > 0);
        medicareUser = sentaraMedicare > 0;
        commericalUser = sentaraGroup > 0 || sentaraIndividual > 0;
      }

      if (brandConfig.brandName === Brands.AVMED) {
        combinationUser =
          avmedMedicare > 0 && (avmedGroup > 0 || avmedIndividual > 0);
        medicareUser = avmedMedicare > 0;
        commericalUserGroup = avmedGroup > 0;
        commericalUserIndividual = avmedIndividual > 0;
      }

      if (combinationUser && types.length > 1) {
        setLobs("Combination");
      } else if (medicareUser) {
        setLobs("Medicare");
      } else if (commericalUserGroup) {
        setLobs("Group");
      } else if (commericalUserIndividual) {
        setLobs("Individual");
      } else if (commericalUser) {
        setLobs("commercial");
      }
    }
  };

  useEffect(() => {
    getUserLobs();
  }, []);

  return (
    <div className="d-flex flex-column h-100">
      <div className="w-100">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div
            className={`${brandConfig.brandName === Brands.AVMED ? "avmed-heading-with-bg" : "heading-with-bg"} mb-3 mt-3`}
          >
            <Headings
              level={1}
              dataTestId="txtProfileCardHeading"
              text={t("eBroker")}
              variant={"bold"}
            />
          </div>
          <div className="d-flex flex-no-wrap align-items-center mb-3">
            <div className="profile-letter-wrapper">
              <ProfileFirstLetter
                firstName={toPascalCase(user?.person?.firstName ?? "")}
                lastName={toPascalCase(user?.person?.lastName ?? "")}
                brandName={brandConfig.brandName}
              />
            </div>
            <div
              className="flex-1 profile-name-text text-break ps-4 ms-3"
              data-testid="txtUserFullName"
            >
              {toPascalCase(user?.person?.firstName ?? "")}{" "}
              {toPascalCase(user?.person?.middleName ?? "")}{" "}
              {toPascalCase(user?.person?.lastName ?? "")}
            </div>
          </div>
          <hr />
          {Brands.AVMED === brandConfig.brandName ? (
            <>
              <div className="font-eighteen line-ht28 mb-3 contact-details-wraper">
                <strong className="me-2" data-testid="lblServiceLabel">
                  {t("account.label.agentSupport")}:
                </strong>
                <span>
                  {lobs === "Individual" ? (
                    <a
                      href={`mailto:${t("account.supportLabelValues.individualSupport")}`}
                    >
                      {t("account.supportLabelValues.individualSupport")}
                    </a>
                  ) : (
                    lobs === "Group" &&
                    t("account.supportLabelValues.commercialSupport")
                  )}
                </span>
              </div>
              {lobs === "Medicare" && (
                <div className="font-sixteen line-ht28 mb-3 contact-details-wraper">
                  <strong className="me-2" data-testid="lbIndividualBusiness">
                    {t("account.label.medicare")}:
                  </strong>
                  <span>{t("account.supportLabelValues.medicareSupport")}</span>
                </div>
              )}
              {lobs === "Combination" && (
                <div>
                  <div className="font-sixteen line-ht28 mb-3 contact-details-wraper">
                    <strong className="me-2" data-testid="lbIndividualBusiness">
                      {t("account.label.individual")}:
                    </strong>
                    <span>
                      <a
                        href={`mailto:${t("account.supportLabelValues.individualSupport")}`}
                      >
                        {t("account.supportLabelValues.individualSupport")}
                      </a>
                    </span>
                  </div>
                  <div className="font-sixteen line-ht28 mb-3 contact-details-wraper">
                    <strong className="me-2" data-testid="lbIndividualBusiness">
                      {t("account.label.medicare")}:
                    </strong>
                    <span>
                      {t("account.supportLabelValues.medicareSupport")}
                    </span>
                  </div>
                  <div className="font-sixteen line-ht28 mb-3 contact-details-wraper">
                    <strong className="me-2" data-testid="lbIndividualBusiness">
                      {t("account.label.commercial")}:
                    </strong>
                    <span>
                      {t("account.supportLabelValues.commercialSupportNumber")}
                    </span>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              {lobs === "Medicare" && (
                <>
                  <div className="font-eighteen line-ht28 contact-details-wraper">
                    <strong className="me-2" data-testid="lblServiceLabel">
                      {t("account.label.medicareSupport1Label")}:
                    </strong>
                  </div>
                  <div className="font-eighteen line-ht28 contact-details-wraper mb-3">
                    {t("account.supportLabelValues.medicareSupport1")}
                  </div>

                  <div className="font-eighteen line-ht28 contact-details-wraper">
                    <strong className="me-2" data-testid="lblServiceLabel">
                      {t("account.label.medicareSupport2Label")}:
                    </strong>
                  </div>
                  <div className="font-eighteen line-ht28 contact-details-wraper mb-3">
                    {t("account.supportLabelValues.medicareSupport2")}
                  </div>
                </>
              )}
              {lobs === "Combination" && (
                <>
                  <div className="font-eighteen line-ht28 contact-details-wraper">
                    <strong className="me-2" data-testid="lblServiceLabel">
                      {t("account.label.commercialServices")}:
                    </strong>
                  </div>
                  <div className="font-eighteen line-ht28 contact-details-wraper mb-3">
                    {t("account.supportLabelValues.commercialSupport")}
                  </div>

                  <div className="font-eighteen line-ht28 contact-details-wraper">
                    <strong className="me-2" data-testid="lblServiceLabel">
                      {t("account.label.medicareSupport1Label")}:
                    </strong>
                  </div>
                  <div className="font-eighteen line-ht28 contact-details-wraper mb-3">
                    {t("account.supportLabelValues.medicareSupport1")}
                  </div>

                  <div className="font-eighteen line-ht28 contact-details-wraper">
                    <strong className="me-2" data-testid="lblServiceLabel">
                      {t("account.label.medicareSupport2Label")}:
                    </strong>
                  </div>
                  <div className="font-eighteen line-ht28 contact-details-wraper mb-3">
                    {t("account.supportLabelValues.medicareSupport2")}
                  </div>
                </>
              )}
              {lobs === "commercial" && (
                <>
                  <div className="font-eighteen line-ht28 contact-details-wraper mb-3">
                    <strong className="me-2" data-testid="lblServiceLabel">
                      {t("account.label.brokerServices")}:
                    </strong>
                    <span>
                      {t("account.supportLabelValues.commercialSupport")}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}

          <MenuAccordion id="acc_side_menu" />
        </div>
      </div>
    </div>
  );
};
