import React, { useEffect } from "react";
import { t } from "i18next";
import { HeroBanner } from "@sentaraui/optimahealth_web";
import HomeTiles from "./HomeTiles";
import bannerImagePath from "../../assets/images/dashboard/banner_img.png";
import bannerImageAvmedPath from "../../assets/images/dashboard/banner_img_avmed.png";
import bannerImageMobilePath from "../../assets/images/dashboard/banner_img_mob.png";
import bannerImageAvmedMobilePath from "../../assets/images/dashboard/banner_img_mob_avmed.png";
import { Brands, useBranding, useGlobalState } from "providers";
import {
    DeviceTypes,
} from "utils/model/CommonTypes";

export const Dashboard = () => {
    const { brandConfig } = useBranding();
    const { deviceType } = useGlobalState();
    const isMobile = deviceType === DeviceTypes.MOBILE;

    useEffect(() => {
        document.title = t("pageTitleADA.dashboard");
    }, []);

    return (
        <div>
            <div className="dashboard">
                <HeroBanner
                    bannerImagePath={brandConfig.brandName === Brands.AVMED ? bannerImageAvmedMobilePath : bannerImageMobilePath}
                    bannerAltText={t("dashboard.label.bannerImgAltText")}
                    title={t('dashboard.label.title')}
                    description={t('dashboard.label.bannerDesc')}
                    buttonText={t('dashboard.label.learnMore')}
                    learnMoreButton={brandConfig.brandName !== Brands.AVMED}
                    buttonLink={brandConfig.link.dashboardLearnMoreLink}
                    ariaLabel={`${brandConfig.link.dashboardLearnMoreLink} ${t("nav.menu.ssoAriaLabel")}`}
                    backgroundColor={!isMobile ? "rgba(34, 112, 102, 0.08)" : ""}
                    backgroundImage={!isMobile ? Brands.AVMED !== brandConfig.brandName ? bannerImagePath : bannerImageAvmedPath : undefined}
                    headingLevel={1}
                    backgroundSize={!isMobile && (brandConfig.brandName === Brands.AVMED ? 'cover' : 'contain')}
                >
                </HeroBanner>
            </div>
            <div className="grid-cont d-flex">
                <div
                    className="left-border-white"
                    aria-hidden="true"
                ></div>

                <HomeTiles />
                <div
                    className="bottom-border-white"
                    aria-hidden="true"
                ></div>
            </div>
        </div >
    )
}

export default Dashboard;