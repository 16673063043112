import SearchListItem from "./SearchListItem";
import { OrderIDList, SelectedDepType } from "utils/model/CommonTypes";

type Props = {
  pageList?: OrderIDList;
  page: number;
  selectedDep?: SelectedDepType;
  setSelectedDep: (v: SelectedDepType) => void;
  getIdentificationCard: Function;
  isMedicare: boolean;
};

const SearchTableDeskTop = ({
  pageList,
  selectedDep,
  page,
  setSelectedDep,
  getIdentificationCard,
  isMedicare,
}: Props) => {
  return (
    <SearchListItem
      {...{
        pageList,
        selectedDep,
        page,
        setSelectedDep,
        getIdentificationCard,
        isMedicare
      }}
    />

  );
};

export default SearchTableDeskTop;
