import {
  BrokerAPIStatus,
  MemberSearchRecord,
  MemberSearchResponse,
} from "@sentara/sentara-api-hooks-core";
import {
  Headings,
  SecondaryButton,
  Link,
  AccordionTable,
  Table
} from "@sentaraui/optimahealth_web";
import Pagination from "organisms/pagination/pagination";
import { useGlobalState } from "providers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { MEMBERSTATUS, listPerTable } from "utils/constants/Constants";
import { VIEW_ELIGIBILITY } from "utils/constants/Routes";
import { convertToDate } from "utils/helpers/DateFormate";
import { DeviceTypes, BROKERTYPE } from "utils/model/CommonTypes";


interface SearchViewEligibilityProps {
  memberList: MemberSearchResponse;
  apiStatus: BrokerAPIStatus;
  lob: string;
}

const SearchViewEligibility = ({
  memberList,
  apiStatus,
  lob
}: SearchViewEligibilityProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;
  let [searchParams] = useSearchParams();
  const [searchFields, setSearchFields] = useState([]);
  const header = [
    { text: t("viewEligibilityForm.groupNumber") },
    { text: t("viewEligibilityForm.memberNumber") },
    { text: t("viewEligibilityForm.memberName") },
    { text: t("viewEligibilityForm.status") },
    { text: t("viewEligibilityForm.dob") },
    { text: t("viewEligibilityForm.primaryCarePhysician") }
  ]

  const medicareHeader = [
    { text: t("viewEligibilityForm.memberNumber") },
    { text: t("viewEligibilityForm.memberName") },
    { text: t("viewEligibilityForm.status") },
    { text: t("viewEligibilityForm.dob") },
    { text: t("viewEligibilityForm.primaryCarePhysician") }
  ]

  const printParamValue = (key: string) => {
    return (
      (["firstName", "lastName"].includes(key)
        ? "'" + searchParams.get(key) + "'"
        : searchParams.get(key)) + (key === "policyId" ? "." : "")
    );
  };

  const getTableData = () => {
    let tableData: any = []
    let rowData = []
    if (memberList.Result.length) {
      memberList?.Result?.map(
        (member: MemberSearchRecord, key) => {
          rowData = []
          if (lob !== "medicare")
            rowData.push({ text: member.groupId })

          rowData.push({
            text: (<Link
              to={`/app/${VIEW_ELIGIBILITY}/${member.memberId}/${lob}`}
            >
              {member.memberId}
            </Link>)
          })

          rowData.push({ text: `${member.firstName} ${member.midName} ${member.lastName}` })
          rowData.push({ text: member?.status && MEMBERSTATUS[member.status] })
          rowData.push({ text: convertToDate(member.dateOfBirth ?? "") })
          rowData.push({ text: member.primaryCarePhysican })

          tableData.push(rowData)
        })
    } else if (apiStatus !== BrokerAPIStatus.PROCESSING) {
      rowData.push({ text: t("noResult") })
      tableData.push(rowData)
    }

    return tableData
  }

  useEffect(() => {
    const params: any = [];
    searchParams.forEach((item: string, key: string) => {
      if (key !== "groupId" && key !== "page" && item && key !== "lineOfBusiness") {
        params.push(
          `${["lastName", "policyId"].includes(key) ? t("viewEligibilityForm.memberWith") : ""} ${t("viewEligibilityForm.key." + key)} ${printParamValue(key)}`
        );
      }
    });
    setSearchFields(params);
  }, [searchParams]);

  return (
    <div>
      <Headings
        className="black-color mt-5 mb-4"
        level={3}
        text={t("viewEligibilityForm.selectMember")}
        dataTestId="txtViewEligibilitySelectMember​"
        variant={"semiBold"}
      />
      <div className="mb-4" data-testid="lblYourSearchKeywordDetails​">
        <strong>{t("viewEligibilityForm.searchFor")}</strong>{" "}
        <span>{searchFields.join(", ")}</span>
      </div>
      <SecondaryButton
        data-testid="btnViewEligibilityNewSearch"
        className={"secondary_btn btn_block_mob mb-4"}
        onClick={() => navigate(`/app/${VIEW_ELIGIBILITY}`)}
      >
        {t("viewEligibilityForm.btnNewSearch")}
      </SecondaryButton>

      <div className="row mb-5">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          {!isMobile && (
            <div className="table-responsive">
              <Table
                data-testid="tblViewEligibilitySearchResultTableGroupDetails"
                header={lob !== "medicare" ? header : medicareHeader}
                rows={getTableData()}
              />
            </div>
          )}

          {isMobile &&
            (memberList.Result.length > 0 ? (
              <div className="mb-4">
                <AccordionTable
                  initialActiveIndex={0}
                  id="view_eligibility_search_table"
                  items={
                    memberList.Result.length > 0 &&
                    memberList?.Result?.map((member: MemberSearchRecord) => ({
                      title: (
                        <div>
                          <div>{t("viewEligibilityForm.groupNumber")}</div>
                          <div>{member.groupId}</div>
                        </div>
                      ),
                      id: "view_eligibility_search_table_data",
                      content: (
                        <>
                          <div className="mb-3">
                            <div>{t("viewEligibilityForm.memberNumber")}</div>
                            <div>
                              <Link
                                to={`/app/${VIEW_ELIGIBILITY}/${member.memberId}`}
                              >
                                {member.memberId}
                              </Link>
                            </div>
                          </div>
                          <div className="mb-3">
                            <div>{t("viewEligibilityForm.memberName")}</div>
                            <div>{`${member.firstName} ${member.midName} ${member.lastName}`}</div>
                          </div>
                          <div className="mb-3">
                            <div>{t("viewEligibilityForm.status")}</div>
                            <div>
                              {member?.status && MEMBERSTATUS[member.status]}
                            </div>
                          </div>
                          <div className="mb-3">
                            <div>{t("viewEligibilityForm.dob")}</div>
                            <div>{convertToDate(member.dateOfBirth ?? "")}</div>
                          </div>
                          <div className="mb-3">
                            <div>
                              {t("viewEligibilityForm.primaryCarePhysician")}
                            </div>
                            <div>{member.primaryCarePhysican}</div>
                          </div>
                        </>
                      ),
                    }))
                  }
                />
              </div>
            ) : (
              <>
                {apiStatus !== BrokerAPIStatus.PROCESSING && (
                  <div className="mb-4">
                    <div className="text-center">{t("noResult")}</div>
                  </div>
                )}
              </>
            ))}
          <Pagination
            total={memberList.total}
            records={listPerTable}
            current={searchParams.get("page") ?? 1}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchViewEligibility;
