import { Link } from "react-router-dom";
import {
  useBenefitPlan,
  useMember,
  DocumentType,
  BrokerDocumentType,
  BrokerAPIStatus,
} from "@sentara/sentara-api-hooks-core";
import {
  Headings,
  PrimaryButton,
  SecondaryButton,
  Table,
} from "@sentaraui/optimahealth_web";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useGlobalState } from "providers";
import { Gender } from "utils/constants/Constants";
import { VIEW_ELIGIBILITY } from "utils/constants/Routes";
import {
  convertToDate,
  dobFormat,
  enrollDateFormat,
  enrollFormat,
} from "utils/helpers/DateFormate";
import { pdfConverter, printDiv } from "utils/helpers/HelperFn";
import { ErrorBy } from "organisms/form";
import { ModalOverlayWithOutClose } from "organisms/popup/ModalOverlayWithOutClose";
import Accumulations from "./Accumulations";

const ViewEligibilityInfo = () => {
  const { memberId, lob } = useParams();
  const navigate = useNavigate();
  const pdfref = useRef<HTMLDivElement>(null);
  const { getMember, state: eligible, status: apiSatus } = useMember();
  const { setPageLoader } = useGlobalState();
  const { getBenefitPlan } = useBenefitPlan();
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<{
    open: boolean;
    content?: string;
    title?: string;
  }>({ open: false, content: "", title: "" });

  useEffect(() => {
    if (memberId) {
      setPageLoader("eligility", true);
      setIsSubmited(true);
      getMember({ memberId: memberId }).then(() => {
        setPageLoader("eligility", false);
        setIsSubmited(false);
      });
    }
  }, [memberId]);

  const openFile = (memberId: string, type: BrokerDocumentType) => {
    setPageLoader("getBenefitPlan", true);
    getBenefitPlan({ memberId, documentType: type }).then((res) => {
      setPageLoader("getBenefitPlan", false);
      if (res?.data?.data) {
        pdfConverter(res?.data?.data, `${memberId}_${type}`);
      } else {
        setOpenInfo({ open: true, content: t("featureUnavailable") });
      }
    });
  };

  const addressTo: Array<string> = [];
  const address = eligible?.Address;
  if (address?.address2) {
    addressTo.push(address.address2);
  }
  if (address?.city) {
    addressTo.push(address.city);
  }
  if (address?.state) {
    addressTo.push(address.state);
  }

  const newSearchBtn = (
    <SecondaryButton
      data-testid="clearButton"
      className={"secondary_btn btn_block_mob"}
      onClick={() => navigate(`/app/${VIEW_ELIGIBILITY}`)}
    >
      {t("viewEligibilityForm.btnNewSearch")}
    </SecondaryButton>
  );

  const getMemberInfoTableData = () => {
    let singleRowData = []
    let tableRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.asOf")} {convertToDate(new Date())}:
      </strong>)
    })
    if (eligible?.Policy.disEnrolledDate)
      singleRowData.push({ text: `${t("viewEligibilityForm.disenrolledAsOf")} ${enrollFormat(eligible.Policy.disEnrolledDate)}` })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.memberName")}:
      </strong>)
    })
    singleRowData.push({ text: `${eligible?.Name.firstName} ${eligible?.Name.middleName} ${eligible?.Name.lastName}` })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.address")}:
      </strong>)
    })
    singleRowData.push({
      text: (<><div>{eligible?.Address.address1}</div>
        <div>
          {addressTo.join(", ")} {eligible?.Address.zip}
        </div></>
      )
    })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.phone")}:
      </strong>)
    })
    singleRowData.push({ text: formatPhoneNumber(eligible?.Telecom.mobilePhone) })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.memberID")}:
      </strong>)
    })
    singleRowData.push({ text: eligible?.Identifier.memberId })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.dob")}:
      </strong>)
    })
    singleRowData.push({ text: dobFormat(eligible?.DateOfBirth!) })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.gender")}:
      </strong>)
    })
    singleRowData.push({ text: Gender[eligible?.gender!] })
    tableRowData.push(singleRowData)

    return tableRowData
  }

  const getPlanInfoTableData = () => {
    let singleRowData = []
    let tableRowData = []

    if (lob === "commercial") {
      singleRowData.push({
        text: (<strong>
          {t("viewEligibilityForm.groupIDNumber")}:
        </strong>)
      })
      singleRowData.push({ text: eligible?.Policy.groupId })
      tableRowData.push(singleRowData)

      singleRowData = []
      singleRowData.push({
        text: (<strong>
          {t("viewEligibilityForm.groupName")}:
        </strong>)
      })
      singleRowData.push({ text: eligible?.Policy.groupName })
      tableRowData.push(singleRowData)
    }

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.plan")}:
      </strong>)
    })
    singleRowData.push({ text: eligible?.Policy.planName })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.planType")}:
      </strong>)
    })
    singleRowData.push({ text: eligible?.Policy.planType })
    tableRowData.push(singleRowData)

    if (!eligible?.Policy.disEnrolledDate) {
      singleRowData = []
      singleRowData.push({
        text: (<strong>
          {t("viewEligibilityForm.enrolledDate")}:
        </strong>)
      })
      singleRowData.push({ text: eligible?.Policy.enrolledDate })
      tableRowData.push(singleRowData)
    }

    if (eligible?.Policy.disEnrolledDate) {
      singleRowData = []
      singleRowData.push({
        text: (<strong>
          {t("viewEligibilityForm.disEnrolledDate")}:
        </strong>)
      })
      singleRowData.push({ text: eligible?.Policy.disEnrolledDate })
      tableRowData.push(singleRowData)

      singleRowData = []
      singleRowData.push({
        text: (<strong>
          {t("viewEligibilityForm.disEnrolledDate")}:
        </strong>)
      })
      singleRowData.push({ text: eligible?.Policy.disEnrolledDate })
      tableRowData.push(singleRowData)

      singleRowData = []
      singleRowData.push({
        text: (<strong style={{ whiteSpace: "pre-line" }}>
          {t("viewEligibilityForm.disEnrolledHistory")}:
        </strong>)
      })
      singleRowData.push({
        text: (<><p>
          <strong style={{ whiteSpace: "pre-line" }}>
            {t("viewEligibilityForm.disEnrolledHistory")}:{" "}
          </strong>{" "}
          {eligible.Policy.groupId}
        </p>
          <p className="mb-0">
            <strong style={{ whiteSpace: "pre-line" }}>
              {t("viewEligibilityForm.coverageDate")}:{" "}
            </strong>
            {convertToDate(eligible.Policy.coverageStartDate)} -{" "}
            {convertToDate(eligible.Policy.coverageEndDate)}
          </p></>)
      })
      tableRowData.push(singleRowData)
    }

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.cordinationOfBenefits")}:
      </strong>)
    })
    singleRowData.push({
      text: (<><div>
        {t("viewEligibilityForm.primaryPay")}{" "}
        {eligible?.Policy.primaryCoordinationOfBenefits || "N/A"}
      </div>
        <div>
          {t("viewEligibilityForm.secondaryPay")}{" "}
          {eligible?.Policy.secondaryCoordinationOfBenefits || "N/A"}
        </div></>)
    })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.pcp")}:
      </strong>)
    })
    singleRowData.push({ text: eligible?.Policy.primaryCarePhysicianName })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.pcpEffectiveDate")}:
      </strong>)
    })
    singleRowData.push({ text: eligible?.Policy.primaryCarePhysicianNameEffectiveDate })
    tableRowData.push(singleRowData)

    return tableRowData
  }

  const getHealthPlanDocTableData = () => {
    let singleRowData = []
    let tableRowData = []

    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.benefitSummary")}:
      </strong>)
    })
    singleRowData.push({
      text: (<PrimaryButton
        data-testid="btnViewBenifitsSummary"
        className={"primary_btn btn-medium btn_block_mob"}
        onClick={() => {
          if (eligible?.Identifier.memberId) {
            openFile(
              eligible.Identifier.memberId,
              BrokerDocumentType.BENEFIT_SUMMARY
            );
          }
        }}
      >
        {t("viewEligibilityForm.view")}
      </PrimaryButton>)
    })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.summaryBenefits")}:
      </strong>)
    })
    singleRowData.push({
      text: (<Link
        to={"#"}
        onClick={(e) => {
          e.preventDefault();
          if (eligible?.Identifier.memberId) {
            openFile(
              eligible.Identifier.memberId,
              BrokerDocumentType.SUMMARY_OF_BENEFIT_COVERAGE
            );
          }
        }}
        className="text-decoration-underline"
        data-testid="txtUniformSummaryEffectiveDate"
      >
        {t("viewEligibilityForm.effective")}
        {":"} {enrollDateFormat(eligible?.Policy.effectiveDate!)}
      </Link>)
    })
    tableRowData.push(singleRowData)

    return tableRowData
  }

  const getCopayTableData = () => {
    let singleRowData = []
    let tableRowData = []

    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.benefitSummary")}:
      </strong>)
    })
    singleRowData.push({ text: eligible?.WalletCard.pcpCoPay })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.preventative")}:
      </strong>)
    })
    singleRowData.push({ text: t("viewEligibilityForm.referPreventAmt") })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.specialist")}:
      </strong>)
    })
    singleRowData.push({ text: eligible?.WalletCard.specialistCoPay })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.ucc")}:
      </strong>)
    })
    singleRowData.push({ text: eligible?.WalletCard.uccCoins })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.er")}:
      </strong>)
    })
    singleRowData.push({ text: eligible?.WalletCard.erCoIns })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({
      text: (<strong>
        {t("viewEligibilityForm.rx")}:
      </strong>)
    })
    singleRowData.push({ text: t("viewEligibilityForm.referCoverageDoc") })
    tableRowData.push(singleRowData)

    return tableRowData
  }

  const meridiem = (_date: string) => {
    if (!_date) {
      return "";
    }
    const d = new Date(_date);
    return d.getHours() > 12 ? "PM" : "AM";
  };

  const formatPhoneNumber = (phoneNumber: any) => {
    // Remove all non-digit characters from the phone number
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    // Format the cleaned phone number into the desired format
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    // If the phone number does not match the expected format, return it as is
    return phoneNumber;
  };
  return (
    <div ref={pdfref}>
      <ModalOverlayWithOutClose
        open={openInfo.open}
        callBack={() => setOpenInfo({ open: false })}
        title={openInfo.title}
        content={openInfo.content}
      />
      <Headings
        className="font-twentyfour black-color mb-3"
        level={2}
        text={t("viewEligibilityForm.memberInfo")}
        dataTestId="txtViewEligibilityMemberInfoTableTitle"
        variant={"semiBold"}
      />
      {apiSatus !== BrokerAPIStatus.INITIATE && eligible ? (
        <>
          <Table
            data-testid="tblMemberInfoTable"
            rows={getMemberInfoTableData()}
          />

          <Headings
            className="font-twentyfour black-color mb-3"
            level={3}
            text={t("viewEligibilityForm.planInfo")}
            dataTestId="txtPlanInformationTableTitle"
            variant={"semiBold"}
          />
          <Table
            data-testid="tblViewEligibilityPlanInfoTableGroupDetails"
            rows={getPlanInfoTableData()}
          />

          <Headings
            className="font-twentyfour black-color mb-3"
            level={3}
            text={t("viewEligibilityForm.hpd")}
            dataTestId="txtHealthPlanDocsTitle"
            variant={"semiBold"}
          />
          <Table rows={getHealthPlanDocTableData()} />

          <Headings
            className="font-twentyfour black-color mb-3"
            level={3}
            text={t("viewEligibilityForm.copay&Coinsurance")}
            dataTestId="txtMemberCopayTitle"
            variant={"semiBold"}
          />

          <Table
            data-testid="tblMemberCopayDetails"
            rows={getCopayTableData()}
          />

          <Accumulations accumulations={eligible?.Accumulations} />
          <div className="mb-4 p-4 border border-radius10 light-grey-bg">
            <strong>{t("viewEligibilityForm.notes")}</strong>{" "}
            {t("viewEligibilityForm.notesContent")}
          </div>
          <hr className="my-4" />
          <div className="d-flex flex-lg-row flex-md-row flex-column gap-4 gap-lg-3 gap-md-3 mb-5 not-in-print">
            <PrimaryButton
              data-testid="printButton"
              onClick={() => {
                printDiv(pdfref);
                return false;
              }}
              className="primary_btn btn_block_mob"
            >
              {t("viewEligibilityForm.printBtn")}
            </PrimaryButton>
            {newSearchBtn}
          </div>
        </>
      ) : (
        <>
          {!isSubmited ? (
            <>
              <ErrorBy message={t("noResult")} isShow={true} />
              <div className="pt-3">{newSearchBtn}</div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};
export default ViewEligibilityInfo;
