import { useEffect } from "react";
import { Headings, Link } from "@sentaraui/optimahealth_web";
import { LINK } from "../../utils/constants/Constants";
import { t } from "i18next";
import CommissionPolicyTable1 from "./CommissionPolicyTable1";
import CommissionPolicyTable2 from "./CommissionPolicyTable2";
import CommissionPolicyMedicarePlanTable from "./CommissionPolicyMedicarePlanTable";
import {
  bonusPayout,
  group_plan,
  guidelines,
  qualificationCriteria,
  commission_plan
} from "utils/constants/CommissionPolicyConstants";
import { Brands, useBranding } from "providers";

export const CommissionPolicy = () => {
  const { brandConfig } = useBranding();
  useEffect(() => {
    document.title = t('pageTitleADA.commissionInformation');
  }, []);
  return (
    <div className="mb-4">
      <Headings
        className="fw-600 mb-4"
        dataTestId="txtCommissionInfoPageTitle"
        level={1}
        text={t("commissionPolicy.header")}
      />
      <div>
        <Headings
          className="fw-600 font-twentyfour black-color mb-3"
          dataTestId="txtCommissionInfoPageSubTitle"
          level={2}
          text={t("commissionPolicy.subHeader")}
          variant={"semiBold"}
        />

        <p className="mb-3" data-testid="txtPolicyStatement">
          {t("commissionPolicy.content1")}
        </p>
        <Headings
          dataTestId="guidelines"
          className="fw-500 font-twenty black-color mb-3"
          level={3}
          text={t("commissionPolicy.guidelines")}
          variant={"medium"}
        />
        <ul className="d-flex flex-column gap-3 list-disc">
          {guidelines.map((guidelines: any, index: number) => (
            <li key={index}>{t(guidelines.content)}</li>
          ))}
        </ul>

        <Headings
          className="fw-500 font-twenty black-color mb-3"
          level={3}
          text={t("commissionPolicy.stateRegulations")}
          variant={"medium"}
        />
        <p>{t("commissionPolicy.stateRegulationsContent")}</p>

        <>
          <p className="redhat_medium font-eighteen">
            <strong>
              {`${t("commissionPolicy.reviewLetters")} `}
              <Link
                to={LINK.VIRGINIA_GOV_LINK}
                className="redhat_medium font-sixteen"
                target="_blank"
                data-testid="lnkVirginiaBureauOfInsurance"
              >
                {t("commissionPolicy.InsuranceWebpage")}
              </Link>
            </strong>
            .
          </p>
        </>

        <p className="mb-4">{t("commissionPolicy.infocontent")}</p>
        <Headings
          dataTestId="individualPlans"
          className="fw-600 font-twentyfour black-color mb-3"
          level={2}
          text={t("commissionPolicy.individualFamilyPlans")}
          variant={"semiBold"}
        />
        <p>{t("commissionPolicy.individualPlanContent1")}</p>
        <p className="mb-4">{t("commissionPolicy.individualPlanContent2")}</p>
        <Headings
          dataTestId="medicareplans"
          className="fw-600 font-twentyfour black-color mb-3"
          level={2}
          text={t("commissionPolicy.medicarePlans")}
          variant={"semiBold"}
        />
        <p className="mb-4">{t("commissionPolicy.medicarePlanContent")}</p>

        <CommissionPolicyMedicarePlanTable />

        <Headings
          dataTestId="groupPlans"
          className="fw-600 font-twentyfour black-color mt-4 mb-4"
          level={2}
          text={t("commissionPolicy.groupPlans")}
          variant={"semiBold"}
        />

        <Headings
          dataTestId="terminationGroupTitle"
          className="fw-500 font-twenty black-color mb-4"
          level={3}
          text={t("commissionPolicy.terminationGroup")}
          variant={"medium"}
        />
        <ul className="d-flex flex-column gap-3 list-disc pb-2">
          {group_plan.map((group: any, index: number) => (
            <li key={index}>{t(group.content)}</li>
          ))}
        </ul>

        <Headings
          className="fw-500 font-twenty black-color mb-4"
          level={3}
          dataTestId="spinoffHeader"
          text={t("commissionPolicy.spinOffGroups")}
          variant={"medium"}
        />
        <p className="mb-4">{t("commissionPolicy.spinOffGroupsContent")}</p>
        <Headings
          className="fw-500 font-twenty black-color mb-4"
          level={3}
          dataTestId="commissionSchedule"
          text={t("commissionPolicy.commissionSchedule")}
          variant={"medium"}
        />
        <p>
          {t("commissionPolicy.commissionSchedule0")}
          <span className="redhat_bolditalic">{t("commissionPolicy.commissionScheduleEdge")}</span>
          {t("commissionPolicy.commissionScheduleRights")}{" "}
          {t("commissionPolicy.commissionSchedule01")}{" "}
        </p>
        <p>
          {t("commissionPolicy.commissionSchedule1.1")}
          <span className="redhat_bolditalic">{t("commissionPolicy.commissionScheduleEdge")}</span>
          {t("commissionPolicy.commissionSchedule1.2")}{" "}
        </p>
        <p className="mb-4">
          {t("commissionPolicy.commissionSchedule4")}{" "}
        </p>

        <CommissionPolicyTable1 />
        
        <p className="mt-4 mb-4">
          {t("commissionPolicy.commissionScheduleTableDescription")}
          <span className="redhat_bolditalic">{t("commissionPolicy.commissionScheduleEdge")}</span>
          {t("commissionPolicy.commissionScheduleTableDescription1")}
        </p>

        <ul className="d-flex flex-column gap-3 list-disc pb-2">
          {commission_plan.map((group: any, index: number) => (
            <li key={index}>{t(group.content)}</li>
          ))}
        </ul>

        <p className="mt-4 mb-4">
          {t("commissionPolicy.commissionScheduleNotes1")}
        </p>

        <p className="mt-4 mb-4">
          {t("commissionPolicy.commissionScheduleContent")}
        </p>

        <CommissionPolicyTable2 />

        <p className="mt-4 mb-4">
          {t("commissionPolicy.commissionScheduleNotes")}
        </p>

        <Headings
          dataTestId="directBrokerProgram"
          className="fw-600 font-twentyfour black-color mb-3"
          level={2}
          text={t("commissionPolicy.directBrokerProgram")}
          variant={"semiBold"}
        />

        <Headings
          dataTestId="qualificationCriteria"
          className="fw-500 font-twenty black-color mb-3"
          level={3}
          text={t("commissionPolicy.qualificationCriteria")}
          variant={"medium"}
        />
        <ul className="d-flex flex-column gap-3 list-disc pb-2">
          {qualificationCriteria.map((group: any, index: number) => (
            <li key={index}>{t(group.content)}</li>
          ))}
        </ul>

        <Headings
          dataTestId="bonusPayout"
          className="fw-500 font-twenty black-color mb-3"
          level={3}
          text={t("commissionPolicy.bonusPayout")}
          variant={"medium"}
        />
        <ul className="d-flex flex-column gap-3 list-disc pb-2">
          {bonusPayout.map((group: any, index: number) => (
            <li key={index}>{t(group.content)}</li>
          ))}
        </ul>
        <p className=" border-bottom pb-4 mb-4">
          {t("commissionPolicy.bonumPayoutNotes")}
        </p>
        <Link
          to={brandConfig.link.homePageLink}
          data-testid="lnkReturnToBrokerHome"
        >
          {t("commissionPolicy.brokerHomePageLink1")}
        </Link>

        <p className="mb-4 mt-4">{t("commissionPolicy.location")}</p>
        {Brands.AVMED !== brandConfig.brandName && (
          <p className="mb-4">{t("commissionPolicy.sentaraNotes")}</p>
        )}

        <div className="d-flex flex-column flex-lg-row flex-md-row justify-content-between gap-3 pb-3">
          <div data-testid="txtUpdatedDate">{t("commissionPolicy.updated")}</div>
          <div className="text-start text-lg-end text-md-end">
            <Link
              to={brandConfig.link.homePageLink}
              target="_blank"
              data-testid="lnkBrokersHomePage"
            >
              {t("commissionPolicy.sentarahealthplans.com/brokers/")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
