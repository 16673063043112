import { FooterLinks, FooterContent, FooterContentLinks } from "@sentaraui/optimahealth_web";
import { Brands, useBranding } from "providers";
import { useTranslation } from "react-i18next";
import { FooterNavType } from "utils/model/CommonTypes";

export const AppFooter = () => {
  const { t } = useTranslation();
  const { brandConfig } = useBranding();

  const navigationLinks = Object.keys(brandConfig.footerNavigation).map(
    (key: string) => {
      const links = brandConfig.footerNavigation;
      const item: FooterNavType = links[key];
      if (key === 'footerContactUs' && brandConfig.brandName === Brands.AVMED) {
        delete links[key]
        return
      } else if (key === 'footerPrivacy' && brandConfig.brandName !== Brands.AVMED) {
        delete links[key]
        return
      }
      return { ...item, label: t(item.label) };
    }
  );

  return (
    <footer className="mt-auto not-print">
      {brandConfig.brandName !== Brands.AVMED ? <><FooterLinks links={navigationLinks} />
        <FooterContent
          copyrightText={t("footer.label.copyrightText", {
            year: new Date().getFullYear(),
          })}
          footerDescription={t("footer.label.disclaimer")}
        /></> : <FooterContentLinks
          links={navigationLinks}
          copyrightText={t("footer.label.copyrightText", {
            year: new Date().getFullYear(),
          })}
          footerDescriptionLine1={t("footer.label.disclaimer1")}
          footerDescriptionLine2={t("footer.label.disclaimer2")}
        >
      </FooterContentLinks>}
    </footer>
  );
};
