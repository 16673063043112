import { useEffect, useState } from "react";
import { Headings } from "@sentaraui/optimahealth_web";
import { t } from "i18next";
import { Form } from "organisms/form/Form";
import OrderMemberIDCardForm from "./OrderMemberIDCardForm";
import OrderMemberIDCardSearch from "./OrderMemberIDCardSearch";
import OrderMemberIDCardHeader from "./OrderMemberIDCardHeader";
import { useSearchParams } from "react-router-dom";
import { useAuth, useGlobalState } from "providers";
import { useMemberSearch } from "@sentara/sentara-api-hooks-core";
import { listPerTable } from "utils/constants/Constants";
import { ErrorBy, GlobalApiError } from "organisms/form";
import { userTypeLob } from "utils/helpers/HelperFn";
import { USERTYPE } from "utils/model/CommonTypes";

export default function OrderMemberIDCard() {
  const { setPageLoader } = useGlobalState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const userType = userTypeLob(user);
  const [userLob, setUserLob] = useState(userType);

  const [userLobSearch, setUserLobSearch] = useState(userType);
  const [searchError, setSearchError] = useState({});
  const [criteriaValidation, setCriteriaValidation] = useState<boolean>(false);
  const [hasDirtyFields, setHasDirtyFields] = useState({})
  const {
    getMemberSearch,
    state: memberList,
    status: apiStatus,
    error,
  } = useMemberSearch();

  const defaultValues = {
    groupId: searchParams.get("groupId") ?? "",
    memberId: searchParams.get("memberId") ?? "",
    subscriberSecurityNumber:
      searchParams.get("subscriberSecurityNumber") ?? "",
    firstName: searchParams.get("firstName") ?? "",
    lastName: searchParams.get("lastName") ?? "",
    checkboxAllMembers: !!["true", "on"].includes(
      searchParams.get("checkboxAllMembers") ?? ""
    ),
  };
  let apiCall = userLob != USERTYPE.MEDICARE ?
  (searchParams.get("groupId") ||
    (searchParams.get("lastName") || searchParams.get("subscriberSecurityNumber") || searchParams.get("memberId"))) :
  (searchParams.get("lastName") || searchParams.get("memberId") || searchParams.get("memberBeneficiaryIdentifier"))


  useEffect(() => {

    document.title = t("pageTitleADA.orderMemberIDCard");
 
    if (apiCall) {
      let params: any = {};
      params["skip"] = 0;
      params["take"] = listPerTable * 10;
      searchParams.forEach((item: string, key: string) => {
        params[key] = item;
      });
      if (params.subscriberSecurityNumber) {
        params.subscriberSecurityNumber =
          params.subscriberSecurityNumber.replaceAll("-", "");
      }
      setPageLoader("eligilitySearch", true);
      if (userLob === "Medicare") {
        params.nationalProducerNumber = user?.npnNumber
      }
      getMemberSearch(params).then(() => {
        setIsSubmited(false);
        setPageLoader("eligilitySearch", false);
        setSearchError({})
      });
    } else {

      setIsSubmited(false);
      setSearchError({})

    }
  }, [searchParams]);

  useEffect(() => {
    if (apiStatus === "success") {
      const ele = document.querySelector(
        memberList?.Result.length ? ".table-responsive" : ".alert-global"
      ) as HTMLDivElement;
      ele?.scrollIntoView({ behavior: "smooth" });

      if (userType != USERTYPE.MEDICARE) {

        setUserLobSearch(userLob);
        setUserLob(USERTYPE.COMMERCIAL);
      }
    }
  }, [apiStatus]);


  useEffect(() => {

    if (criteriaValidation) {

      setSearchError({});

    } else if (error) {
      setSearchError(error)
      if (Object.keys(hasDirtyFields).length === 0) {
        setSearchError({})
      }

    }

  }, [error, criteriaValidation])

  const onSubmit = (formData: any) => {

    const { searchInput, ...rest } = formData;
    const search = { ...rest, page: 1 };
    for (const i in search) {
      if (searchParams.get(i) !== search[i].toString()) {
        setIsSubmited(true);
        setSearchParams(search);
        break;
      }
    }
  };

  const getCommercialBanner = () => {
    return <p className="mb-0">{t("orderMemberIDCard.commercialSubHeaderContent1")}<strong> {t("orderMemberIDCard.must")}</strong> {t("orderMemberIDCard.commercialSubHeaderContent2")}<strong> {t("orderMemberIDCard.and")}</strong> {t("orderMemberIDCard.commercialSubHeaderContent3")} <strong> {t("orderMemberIDCard.and")}</strong> {t("orderMemberIDCard.commercialSubHeaderContent4")} </p>
  }
  return (
    <div>
      <OrderMemberIDCardHeader />
      {memberList?.Result.length &&
         searchParams.get("lastName") !== null &&
        !isSubmited ? (
        <OrderMemberIDCardSearch memberList={memberList} isMedicare={(userType === USERTYPE.MEDICARE || userType === USERTYPE.COMBINED) && (userLobSearch === USERTYPE.MEDICARE)} />
      ) : (
        <div>
          <Headings
            className="fw-600 font-twentyfour black-color mb-4"
            level={2}
            text={t("orderMemberIDCard.subHeader")}
            dataTestId="lblOrderMemberIdCardSearchTitle"
            variant={"semiBold"}
          />
          <div className="d-flex flex-column gap-3 p-3 border border-radius10 mb-4 light-grey-bg">
            {(userType === USERTYPE.COMBINED && (userLob === USERTYPE.COMMERCIAL || userLob === USERTYPE.COMBINED)) && <p className="mb-0"> {t("orderMemberIDCard.medicareHeader")}</p>}
            {(userType === USERTYPE.COMBINED && (userLob === USERTYPE.COMMERCIAL || userLob === USERTYPE.COMBINED)) && (getCommercialBanner())}
            {(userType === USERTYPE.MEDICARE || userType === USERTYPE.COMBINED) && (userLob === USERTYPE.MEDICARE) && t("orderMemberIDCard.subHeaderContentMedicare")}

            {(userType === USERTYPE.COMMERCIAL && userLob === USERTYPE.COMMERCIAL) && t("orderMemberIDCard.subHeaderContent")}
            <p className="mb-0">
              <strong>{t("viewEligibilityForm.subHeaderContent1")}</strong>
            </p>

            <p className="mb-0">{t("orderMemberIDCard.subHeaderContent2")}</p>
          </div>
          <ErrorBy
            isShow={
              !!(
                !isSubmited &&
                memberList?.Result &&
                !memberList?.Result.length &&
                Array.from(searchParams).length
              )
            }
            message={t("unableToLocate")}
          />
          <p className="mb-0">
            <GlobalApiError component={'orderMemberID'} apiError={searchError} />
          </p>
          <Form defaultValues={defaultValues} onSubmit={onSubmit}>
            <OrderMemberIDCardForm userLob={userLob} setUserLob={setUserLob} userType={userType} criteriaValidation={criteriaValidation} setSearchError={setSearchError} setCriteriaValidation={setCriteriaValidation} setHasDirtyFields={setHasDirtyFields} />
          </Form>
          <p className="mt-4 mb-4">
            {t("viewEligibilityForm.matchCriteriaText")}
          </p>
        </div>
      )}
    </div>
  );
}
