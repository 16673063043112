import { useSearchParams } from "react-router-dom";
import { Button } from "@sentaraui/optimahealth_web";
import { RightArrowIcon } from "organisms/icons";
import { Brands, useBranding } from "providers";

interface Props {
  total: number;
  records: number;
  current: number | string;
  callBack?: (v: number) => void;
}

const Pagination = (props: Props) => {
  const { total, records, current, callBack } = props;
  let [searchParams, setSearchParams] = useSearchParams();
  const max = Math.ceil(total / records);
  const _current = parseInt(`${current}`) > max ? max : parseInt(`${current}`);
  const { brandConfig } = useBranding();

  const nav = (page: number) => {
    if (callBack) {
      callBack(page);
    } else {
      const newParams: any = {};
      searchParams.forEach((item, key) => {
        newParams[key] = item;
      });
      newParams["page"] = page;
      setSearchParams(newParams);
    }
  };

  return (
    <div className="d-flex align-items-center pt-2 pb-2">
      {_current > 1 && (
        <Button
          onClick={() => nav(_current - 1)}
          data-testid="pageNavigateIcon"
          className="d-flex align-items-center previous_icon page_button bg-transparent border-0 p-0"
        >{<RightArrowIcon />}</Button>
      )}
      <span
        data-testid="pagesCounts"
        className={`font-fourteen fw-400 ${_current > 1 && "ps-2"} ${_current < max && "pe-2"}`}
      >
        <span className="pe-1">Page</span> {`${_current} / ${max}`}
      </span>
      {_current < max && (
        <Button
          onClick={() => nav(_current + 1)}
          data-testid="pageNavigateIcon"
          className="d-flex align-items-center next_icon page_button bg-transparent border-0 p-0"
        >{<RightArrowIcon />}</Button>
      )}
    </div>
  );
};

export default Pagination;
