import { ProfileRegex } from "utils/constants/Regex";
import { Brands } from "providers";

/* first letter interface */
interface FirstLetter {
  firstName: string;
  lastName: string;
  brandName: string;
}

export const ProfileFirstLetter = ({ firstName = '', lastName = '', brandName = '' }: FirstLetter) => {
  /* Detemine user first letter */
  const getUserFirstLetter = () => {
    const name = `${firstName} ${lastName}`;
    const matches = name.match(ProfileRegex);
    return matches?.join('') || '';
  };
  
  return (
    <>
      <div
        data-testid="profileCardInitials"
        className={`d-flex rounded-circle mx-auto justify-content-center align-items-center ${brandName === Brands.AVMED ? "rounded-circle-profile-image-small": "profile-image-small"} profile-name-text`}
      >
        {getUserFirstLetter()}
      </div>
    </>
  );
}
