import { useTranslation } from "react-i18next";
import { COMMISSION_POLICY } from "utils/constants/Constants";
import { Table } from "@sentaraui/optimahealth_web";

export default function CommissionPolicyTable2() {
  const { t } = useTranslation();
  const header = [
    { text: t("commissionPolicy.eligibleEmployees") },
    { text: t("commissionPolicy.rate") },
  ]

  const getComissionPolicyData = () => {
    let singleRowData = []
    let tableRowData = []

    singleRowData.push({ text: COMMISSION_POLICY.ELIGIBLE_EMPLOYEES1 })
    singleRowData.push({ text: COMMISSION_POLICY.RATE1 })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({ text: COMMISSION_POLICY.ELIGIBLE_EMPLOYEES2 })
    singleRowData.push({ text: COMMISSION_POLICY.RATE2 })
    tableRowData.push(singleRowData)

    singleRowData = []
    singleRowData.push({ text: COMMISSION_POLICY.ELIGIBLE_EMPLOYEES3 })
    singleRowData.push({ text: COMMISSION_POLICY.RATE3 })
    tableRowData.push(singleRowData)

    return tableRowData
  }

  return (
    <div className="row justify-content-center">
      <div className="col-lg-10 col-md-9 col-sm-12 col-12">
        <div className="text-center mb-3 mt-2">
          <strong>  {t("commissionPolicy.tableHeader2")}</strong>
        </div>
        <Table
          data-testid="tblStandardLargeGroupCommissions"
          header={header}
          rows={getComissionPolicyData()} />
      </div>
    </div>
  );
}
