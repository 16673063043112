import { Route, Routes, useNavigate } from "react-router-dom";
import { ProtectedRoute, oidcConfig } from "organisms/auth";
import { AppRoute, AuthRoute } from "./";
import {
  useAuth,
  useGlobalState,
  OidcAuthProvider,
  useBranding
} from "providers";
import { BrokerApiProvider } from "@sentara/sentara-api-hooks-core";
import { User } from "oidc-client-ts";
import { DeviceManagementRoute } from "./DeviceManagementRoute";
import { DEVICE_MANAGEMENT_PORTAL } from "utils/constants/Routes";
import { APPLICATION_TYPE } from "utils/constants/Constants";

export const Routers = () => {
  const { ENVTYPES } = useGlobalState();
  const { BASE_URL: baseURL, OPC_APIM_SUB_KEY: OcpApimSubscriptionKey } =
    ENVTYPES;
  const { token } = useAuth();
  const navigate = useNavigate();
  const { brandConfig } = useBranding();

  const tokenEndpointWithBrandName =
    oidcConfig.metadata.token_endpoint + "&brand=" + brandConfig.brandName + "&type=" + APPLICATION_TYPE;

  const _oidcConfig = {
    ...oidcConfig,
    metadata: {
      ...oidcConfig.metadata,
      token_endpoint: tokenEndpointWithBrandName,
    },
    client_id: brandConfig.oidcConfig.client_id,
    onSigninCallback: (_user: User | void): void => {
      navigate("/");
    },
  };

  return (
    <OidcAuthProvider {..._oidcConfig}>
      <BrokerApiProvider
        httpConfig={
          token
            ? {
              baseURL,
              commonHeaders: {
                "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
                Authorization: `Bearer ${token}`,
              },
            }
            : {}
        }
      >
        <Routes>
          <Route path="/*" element={<AuthRoute />} />
          <Route
            path="/app/*"
            element={
              <ProtectedRoute>
                <AppRoute />
              </ProtectedRoute>
            }
          />
          <Route
            path={`/${DEVICE_MANAGEMENT_PORTAL}/*`}
            element={
              <ProtectedRoute>
                <DeviceManagementRoute />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrokerApiProvider>
    </OidcAuthProvider>
  );
};
