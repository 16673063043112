import { useUpdateSORC } from "@sentara/sentara-api-hooks-core";
import {
  PrimaryButton,
  SecondaryButton,
  Headings,
  Link,
} from "@sentaraui/optimahealth_web";
import { t } from "i18next";
import { ModalOverlayWithOutClose } from "organisms/popup/ModalOverlayWithOutClose";
import { Brands, useAuth, useBranding, useGlobalState } from "providers";
import { useState } from "react";

const Sorc = () => {
  const { brandConfig } = useBranding();
  const [accept, setAccept] = useState(1);
  const { updateUser, user } = useAuth();
  const list: [] = t("sorc.list", { returnObjects: true });
  const { putSORC } = useUpdateSORC();
  const { setPageLoader } = useGlobalState();
  const [visibleMoreInfo, setVisibleMoreInfo] = useState<boolean>(false);

  const updateisSORCurrent = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!user?.isSORCurrent && user?.person?.personID) {
      setPageLoader("putSORC", true);
      putSORC({ personID: user?.person?.personID.toString() }).then((res) => {
        setPageLoader("putSORC", false);
        updateUser({ isSORCurrent: res?.data ? res?.data?.result : false });
      });
    }
  };
  return (
    <div className="mt-4 pt-2 mb-2 pb-1">
      <div className="row justify-content-center">
        <div
          className="col-lg-10 col-md-12 col-sm-12 col-12 black-color"
          aria-live="polite"
        >
          {accept === 1 && (
            <>
              <Headings
                className="fw-600 mb-4"
                dataTestId="txtSorcTitle"
                level={1}
                text={t("sorc.heading_1")}
              />
              <Headings
                className="font-twentyfour fw-600"
                level={2}
                text={t("sorc.heading_2")}
                dataTestId="txtSorcSubTitle1"
                variant={"semiBold"}
              ></Headings>
              <p>{t("sorc.paragraph_1")}</p>
              <div className="mt-4 mb-3">
                <Headings
                  className="font-twentyfour fw-600"
                  level={2}
                  text={t("sorc.heading_4")}
                  dataTestId="txtSorcSubTitle3"
                  variant={"semiBold"}
                />
              </div>
              <p>{t("sorc.paragraph_2")}</p>
              <ol className="d-flex flex-column gap-3 mb-4 ps-4">
                {list.map((item, key) => (
                  <li key={"_"+key}>{item}</li>
                ))}
              </ol>
              <p className="mb-4 pb-2">{t("sorc.paragraph_3")}</p>
              <div className="d-flex flex-column flex-lg-row flex-md-row gap-3 pt-1 pb-5">
                <PrimaryButton
                  type="button"
                  className="primary_btn btn_block_mob"
                  aria-label={t("account.label.accept")}
                  onClick={() => {
                    setAccept(2);
                  }}
                  data-testid="btnSorcIAccept"
                >
                  {t("account.label.accept")}
                </PrimaryButton>
                <SecondaryButton
                  type="button"
                  aria-label={t("account.label.notAccept")}
                  onClick={() => {
                    setAccept(3);
                  }}
                  className="secondary_btn btn_block_mob"
                  data-testid="btnSorcIDontAccept"
                >
                  {t("account.label.notAccept")}
                </SecondaryButton>
              </div>
            </>
          )}
          {accept === 2 && (
            <div className="mt-4 pt-2 mb-2 pb-1" data-testid="sorc_agreed">
              <Headings
                className="fw-600 mb-4"
                dataTestId="txtSorcSubTitle3"
                level={1}
                text={t("sorc.heading_1")}
              />
              <p>
                {t("sorc.secondScreenText")}{" "}
                <Link
                  to="/app"
                  className="link-blue"
                  data-testid="lnkSorcClickToProceed"
                  onClick={(e: any) => updateisSORCurrent(e)}
                >
                  {t("sso.clickHere")}
                </Link>{" "}
                {t("sorc.secondScreenToProceed")}
              </p>
            </div>
          )}
          {accept === 3 && (
            <div className="mt-4 pt-2 mb-2 pb-1" data-testid="sorc_agreed">
              <Headings
                className="fw-600 mb-4"
                dataTestId="txtSorcwarning"
                level={1}
                text={t("sorc.warningHeading")}
              />
              <p>{t("sorc.warning")} </p>

              <p>
                {t("sorc.warningQuery")}{" "}
                <Link
                  to={brandConfig.link.sorc}
                  className="link-blue"
                  data-testid="lnkBrokerRewardProgramBrokerRelationsEmail2"
                >
                  {t("sorc.mailto")}
                </Link>
                {Brands.AVMED === brandConfig.brandName && (
                  <>
                    <span className="px-1">/</span>
                    <span>{t("sorc.group")}</span>
                    <Link
                      to={brandConfig.link.sorc1}
                      className="link-blue"
                      data-testid="lnkBrokerRewardProgramBrokerRelationsEmail2"
                    >
                      {t("sorc.mailto2")}
                    </Link>
                  </>
                )}
              </p>

              <div className="d-flex flex-column flex-lg-row flex-md-row gap-3 pt-1 pb-5 mt-4">
                <PrimaryButton
                  type="button"
                  className="primary_btn btn_block_mob"
                  aria-label={t("sorc.returnStatementBtn")}
                  onClick={() => {
                    setAccept(1);
                  }}
                  data-testid="btnSorcRetunToStatement"
                >
                  {t("sorc.returnStatementBtn")}
                </PrimaryButton>
                <SecondaryButton
                  type="button"
                  aria-label={t("sorc.moreInfo")}
                  onClick={() => {
                    setVisibleMoreInfo(true);
                  }}
                  className="secondary_btn btn_block_mob"
                  data-testid="btnSorcMoreInfo"
                >
                  {t("sorc.moreInfo")}
                </SecondaryButton>
              </div>
            </div>
          )}

          <ModalOverlayWithOutClose
            open={visibleMoreInfo}
            callBack={() => setVisibleMoreInfo(false)}
            title={t("session.label.modalTitle")}
            content={t("sorc.moreInfoAlert")}
          />
        </div>
      </div>
    </div>
  );
};

export default Sorc;
