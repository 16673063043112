import React from 'react'
import { useTranslation } from "react-i18next";
import HamburgerMenu from "../hamburgerMenu/HamburgerMenu";
import LogoImg from "../../assets/images/sentara_logo.svg";
import AvMedLogoImg from "../../assets/images/avmed_logo.svg";
import { ButtonLink, Image, MailIcon } from "@sentaraui/optimahealth_web";
import { HeaderTopNav } from "./HeaderTopNav";
import { ProfileMenu } from "./ProfileMenu";
import { ContactLink, PasswordHelp } from "utils/constants/Navigation";
import { useNavigate } from "react-router";
import { BROKER_DASHBOARD } from "utils/constants/Routes";
import { Brands, useAuth, useBranding } from "providers";
import { ErrorBy } from "organisms/form";
import { useState } from "react";
import { LINK } from "utils/constants/Constants";

export const AppHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { brandConfig } = useBranding();
  const contactUsLink = brandConfig.brandName === Brands.AVMED ? brandConfig.memberBrokerContactUs : ContactLink;

  const isHidden = sessionStorage.getItem("HideServiceNowMessage");
  const [isShowErrorMessage, setShowErrorMessage] = useState<boolean>(isHidden !== "true");

  const HideErrorMessage = () => {
    sessionStorage.setItem("HideServiceNowMessage", "true");
    setShowErrorMessage(false);
  }

  const ChangeFavIcon = (src: string) => {
    const link = document.createElement('link');
    const oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = src;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  };

  React.useEffect(() => {
    if (brandConfig.brandName === Brands.AVMED) {
      document.title = t("pageTitleADA.dashboard");
    }
  }, []);

  React.useEffect(() => {
    ChangeFavIcon(brandConfig.image.faviconImage);
  });

  return (
    <header className="not-print">
      {user?.person && (
        <div className="border-bottom d-none d-xl-block d-lg-block">
          <div className="container-fluid">
            <HeaderTopNav />
          </div>
        </div>
      )}

      <div className="">
        <div className="container-fluid">
          <div>
            <nav className="d-flex align-items-center justify-content-between pt-3 mb-4 mt-1 pb-2">
              <div>
                <HeaderLogo />
              </div>
              <div>
                <div className="d-flex flex-no-wrap align-items-center">
                  <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                    <div className="dark-gray-color">
                      <ButtonLink
                        isHeader={true}
                        buttonText={t("contactUs")}
                        dataTestId="btnContactUs"
                        icon={
                          <MailIcon
                            width={16}
                            height={16}
                            dataTestId="icoMailIcon"
                          />
                        }
                        onClick={() => {
                          window.open(contactUsLink, '_blank');
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-none d-xl-block d-lg-block d-md-none ms-4">
                    {user?.person && <ProfileMenu />}
                  </div>
                  <div className="d-lg-none d-md-block d-sm-block">
                    <HamburgerMenu />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <hr className="d-block d-md-none d-lg-block mt-0" />
      </div>
      <div className="container-fluid">
        <ErrorBy
          isShow={isShowErrorMessage}
          message={(brandConfig.brandName === "AVMED" ? t("viewEligibilityForm.subHeaderMessageContent1Avmed") + '<a href="' + PasswordHelp + '" target="_blank" aria-label="' + PasswordHelp + " " + t("nav.menu.ssoAriaLabel") + '">here</a>' : t("viewEligibilityForm.subHeaderMessageContent1Sentara") + '<a href="' + LINK.SERVICE_NOW_PASSWORD_ENROLLMENT + '" target="_blank" aria-label="' + LINK.SERVICE_NOW_PASSWORD_ENROLLMENT + " " + t("nav.menu.ssoAriaLabel") + '">here</a>') + t("viewEligibilityForm.subHeaderMessageContent3")}
          closeButton={true}
          hideErrorMessage={HideErrorMessage}
        />
      </div>
    </header >
  );
};

interface HeaderLogoProps {
  isFromAuth?: boolean;
}

export const HeaderLogo = ({ isFromAuth }: HeaderLogoProps) => {
  const { brandConfig } = useBranding();

  const logoImagePath =
    brandConfig.brandName === Brands.AVMED ? AvMedLogoImg : LogoImg;

  const { t } = useTranslation();
  return (
    <a href={isFromAuth ? brandConfig.homeLink : `/app/${BROKER_DASHBOARD}`} className="logo-img">
      <Image
        dataTestId={"imgBrokerPortalLogo"}
        path={logoImagePath}
        alttext={t("appLogo")}
        className="img-responsive"
      />
    </a>
  );
};
