import { Headings, AccordionTable, Table } from "@sentaraui/optimahealth_web";
import { Accumulation } from "@sentara/sentara-api-hooks-core";
import { t } from "i18next";
import { useGlobalState } from "providers";
import { DeviceTypes } from "utils/model/CommonTypes";

const Accumulations = ({
  accumulations,
}: {
  accumulations: Array<Accumulation>;
}) => {
  const { deviceType } = useGlobalState();
  const isMobile = deviceType === DeviceTypes.MOBILE;
  
  const accumulationDom = () => {
    const list: { [x: string]: { title: string; item: Array<Accumulation> } } = {};
    const header = [
      { text: t("viewEligibilityForm.member") },
      { text: t("viewEligibilityForm.family") },
    ]
    accumulations?.map((_item, k: number) => {
      const ColHead = _item.collectionHeader.trim();
      switch (ColHead) {
        case "DEDUCTIBLE":
          list["DEDUCTIBLE"] = list["DEDUCTIBLE"] ?? { item: [] };
          list["DEDUCTIBLE"].title = t(
            "viewEligibilityForm.accumulation.deductible"
          );
          list["DEDUCTIBLE"].item.push(_item);
          break;
        case "IN NETWORK MOOP":
          list["INNETWORKMOOP"] = list["INNETWORKMOOP"] ?? { item: [] };
          list["INNETWORKMOOP"].title = t(
            "viewEligibilityForm.accumulation.in"
          );
          list["INNETWORKMOOP"].item.push(_item);
          break;
        case "OUT OF NETWORK MOOP":
          list["OUTOFNETWORKMOOP"] = list["OUTOFNETWORKMOOP"] ?? { item: [] };
          list["OUTOFNETWORKMOOP"].title = t(
            "viewEligibilityForm.accumulation.out"
          );
          list["OUTOFNETWORKMOOP"].item.push(_item);
          break;
      }
    });

    const getAccumilationTableData = (data: { title: string; item: Array<Accumulation> }) => {
      let tableData: any = []
      let rowData = []

      data.item.map((item) => {
        rowData = []

        rowData.push({
          text: `${item.memberAmount ? item.memberAmount : "$00.00"} of${" "}
         ${item.memberLimit ? item.memberLimit : "$00.00"}`
        })
        rowData.push({
          text: `${item.familyAmount ? item.familyAmount : "$00.00"} of${" "}
          ${item.familyLimit ? item.familyLimit : "$00.00"}`
        })

        tableData.push(rowData)
      })
    }

    return Object.keys(list).map((k) => {
      const data = list[k];
      return (
        <div key={k}>
          <div className="font-eighteen black-color mb-3">
            <strong>{data.title}</strong>
          </div>
          <div className="mb-4">
            {!isMobile &&
              <Table
                data-testid="tblDeductibleInfoTable"
                header={header}
                rows={getAccumilationTableData(data)}
              />
            }
            {isMobile &&
              <AccordionTable
                initialActiveIndex={0}
                id="accumulations_details_table"
                items={data.item.map((item) => ({
                  title: (
                    <div>
                      {item.benefitDescription}
                    </div>
                  ),
                  id: "accumulations_details_table_data",
                  content: (
                    <>
                      <div className="mb-3">
                        <div>{t("viewEligibilityForm.member")}</div>
                        <div>
                          {item.memberAmount ? item.memberAmount : "$00.00"} of{" "}
                          {item.memberLimit ? item.memberLimit : "$00.00"}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div>{t("viewEligibilityForm.family")}</div>
                        <div>
                          {item.familyAmount ? item.familyAmount : "$00.00"} of{" "}
                          {item.familyLimit ? item.familyLimit : "$00.00"}
                        </div>
                      </div>
                    </>
                  ),
                }))}
              />
            }
          </div>
        </div >
      );
    });
  };
  return (
    <>
      <Headings
        className="font-twentyfour black-color mb-4"
        level={3}
        text={t("viewEligibilityForm.deductiblesInfo")}
        dataTestId="txtDeductibleInfoTitle"
        variant={"semiBold"}
      />
      {accumulations?.length ? (
        accumulationDom()
      ) : (
        <div className="mb-4 p-4 border border-radius10 light-grey-bg">
          <strong>{t("viewEligibilityForm.noDeductiblesTitle")}</strong>{" "}
          {t("viewEligibilityForm.noDeductiblesNote")}
        </div>
      )}
    </>
  );
};
export default Accumulations;
